@font-face {
  font-family: Lovelo-Black;
  font-style: normal;
  font-weight: 400;
  src: url("Lovelo-Black.e08c7476.eot");
  src: url("Lovelo-Black.e08c7476.eot#iefix") format("embedded-opentype"), url("Lovelo-Black.7cb457c9.woff2") format("woff2"), url("Lovelo-Black.0d51f2c1.woff") format("woff"), url("Lovelo-Black.dfbdd9e6.ttf") format("truetype");
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  src: url("IBMPlexSans-Regular.be88fa4f.eot");
  src: url("IBMPlexSans-Regular.be88fa4f.eot#iefix") format("embedded-opentype"), url("IBMPlexSans-Regular.1b68bd69.woff2") format("woff2"), url("IBMPlexSans-Regular.e5b0e36c.woff") format("woff"), url("IBMPlexSans-Regular.d4108d41.ttf") format("truetype");
}

:root {
  --xl: 4rem;
  --l: 3.2rem;
  --m: 2.4rem;
  --reg: 1.8rem;
  --s: 1.6rem;
  --xs: 1.2rem;
  --v-gutter: 10vh;
  --h-gutter: 10vw;
  --primary: #487f3e;
  font-size: 62.5%;
}

@media (min-width: 23em) {
  :root {
    --xl: 4rem;
  }
}

@media (min-width: 48em) {
  :root {
    --v-gutter: 22vh;
    --h-gutter: 18vw;
  }
}

@media (min-width: 64em) {
  :root {
    --xl: 6.4rem;
    --l: 4.8rem;
    --m: 3.2rem;
    --reg: 2.4rem;
    --v-gutter: 25vh;
    --h-gutter: 20vw;
  }
}

body {
  color: #4e4f4e;
  font-family: IBM Plex Sans, sans-serif;
  font-size: var(--reg);
  text-align: center;
  background-color: #222126;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4 {
  text-align: center;
  font-family: Lovelo-Black, serif;
}

h1 {
  font-size: var(--xl);
}

h2 {
  font-size: var(--l);
}

h3 {
  font-size: var(--m);
}

a {
  color: var(--primary);
}

article {
  overflow: hidden;
}

.get-scrolling {
  border-radius: var(--xl);
  border: .8rem solid var(--primary);
  color: #000;
  cursor: pointer;
  font-family: Lovelo-Black, sans-serif;
  font-size: var(--reg);
  padding: var(--s) var(--m);
  background: #fbfbfb;
  align-items: center;
  margin: auto;
  transition: box-shadow .15s ease-in-out;
  display: flex;
  box-shadow: .6rem .8rem #274422;
}

.get-scrolling:focus, .get-scrolling:hover {
  box-shadow: .4rem .6rem #274422;
}

.get-scrolling:active {
  box-shadow: .2rem .4rem #274422;
}

.get-scrolling small {
  font-size: var(--xs);
  font-style: italic;
  display: block;
}

.get-scrolling svg {
  height: var(--m);
  width: var(--m);
  margin-left: var(--xs);
}

.article__section {
  padding: var(--v-gutter) var(--h-gutter);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.article__section:after {
  border-bottom: 2.4rem double var(--primary);
  content: "";
  height: 0;
  width: 100%;
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(1.2rem)skewY(.7deg);
}

@supports (-webkit-touch-callout: none) {
  .article__section {
    background-attachment: initial;
  }
}

.article__section:nth-child(1) {
  background-image: url("carson-arias-EMB-s2f5c0A-unsplash.227631d8.jpg");
  background-position-y: 25%;
}

.article__section:nth-child(2) {
  background-image: url("kenny-eliason-LoV0EpSG2-M-unsplash.dea71c16.jpg");
}

.article__section:nth-child(3) {
  background-image: url("marvin-meyer-2SDjvx5jEZQ-unsplash.6c4d500c.jpg");
}

.article__section:nth-child(4) {
  background-image: url("andrew-knechel-gG6yehL64fo-unsplash.ec755ed7.jpg");
}

.article__section:nth-child(5) {
  background-image: url("zane-lee-BbXYnYigR0U-unsplash.9818df8b.jpg");
}

.article__section:nth-child(6) {
  min-height: calc(100vh - var(--v-gutter) * 2);
  background-image: url("armando-castillejos-a1GYyF9mt_8-unsplash.3e8abd04.jpg");
}

.article__header, .article__body {
  border: .8rem solid var(--primary);
  max-width: 80rem;
  padding: 1.6rem var(--m);
  background: #fff;
}

.article__header {
  margin: var(--m) auto;
}

.article__body {
  text-align: left;
  margin: 2.4rem auto;
}

.article__body.tl-dr {
  font-size: var(--reg);
}

.article__body.tl-dr h2 {
  font-size: var(--m);
}

.article__body h1, .article__body h2, .article__body h3, .article__body h4 {
  text-align: left;
}

/*# sourceMappingURL=index.6609dae1.css.map */
