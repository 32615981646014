@font-face {
  font-family: "Lovelo-Black";
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Lovelo-Black.eot');
  src: url('./fonts/Lovelo-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Lovelo-Black.woff2') format('woff2'), url('./fonts/Lovelo-Black.woff') format('woff'), url('./fonts/Lovelo-Black.ttf') format('truetype');
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/IBMPlexSans-Regular.eot');
  src: url('./fonts/IBMPlexSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/IBMPlexSans-Regular.woff2') format('woff2'), url('./fonts/IBMPlexSans-Regular.woff') format('woff'), url('./fonts/IBMPlexSans-Regular.ttf') format('truetype');
}

:root {
  font-size: 62.5%;

  --xl: 4rem;
  --l: 3.2rem;
  --m: 2.4rem;
  --reg: 1.8rem;
  --s: 1.6rem;
  --xs: 1.2rem;
  --v-gutter: 10vh;
  --h-gutter: 10vw;
  --primary: rgb( 72, 127, 62);

  @media (min-width: 23em) {
    --xl: 4rem;
  }

  @media (min-width: 48em) {
    --v-gutter: 22vh;
    --h-gutter: 18vw;
  }

  @media (min-width: 64em) {
    --xl: 6.4rem;
    --l: 4.8rem;
    --m: 3.2rem;
    --reg: 2.4rem;
    --v-gutter: 25vh;
    --h-gutter: 20vw;
  }
}

body {
  background-color: rgb(34, 33, 38);
  color: rgb(78, 79, 78);
  font-family: "IBM Plex Sans", sans-serif;
  margin: 0;
  padding: 0;
  font-size: var(--reg);
  text-align: center;
}

h1,
h2,
h3,
h4 {
  font-family: "Lovelo-Black", serif;
  text-align: center;
}

h1 {
  font-size: var(--xl);
}

h2 {
  font-size: var(--l);
}

h3 {
  font-size: var(--m);
}

a {
  color: var(--primary);
}

article {
  overflow: hidden;
}

.get-scrolling {
  align-items: center;
  background: rgb(251, 251, 251);
  border-radius: var(--xl);
  border: 0.8rem solid var(--primary);
  box-shadow: 0.6rem 0.8rem 0 0 rgb(39, 68, 34);
  color: black;
  cursor: pointer;
  display: flex;
  font-family: "Lovelo-Black", sans-serif;
  font-size: var(--reg);
  margin: auto;
  padding: var(--s) var(--m);
  transition: box-shadow 0.15s ease-in-out;

  &:focus,
  &:hover {
    box-shadow: 0.4rem 0.6rem 0 0 rgb(39, 68, 34);
  }

  &:active {
    box-shadow: 0.2rem 0.4rem 0 0 rgb(39, 68, 34);
  }

  small {
    display: block;
    font-size: var(--xs);
    font-style: italic;
  }

  svg {
    height: var(--m);
    width: var(--m);
    margin-left: var(--xs);
  }
}

.article {
  &__section {
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    padding: var(--v-gutter) var(--h-gutter);
    position: relative;

    &::after {
      border-bottom: 2.4rem double var(--primary);
      bottom: 0;
      content: "";
      display: block;
      height: 0;
      left: 0;
      position: absolute;
      transform: translateY(1.2rem) skewY(0.7deg);
      width: 100%;
      z-index: 1;
    }

    @supports (-webkit-touch-callout: none) {
      background-attachment: initial;
    }

    &:nth-child(1) {
      background-image: url("./img/carson-arias-EMB-s2f5c0A-unsplash.jpg");
      background-position-y: 25%;
    }

    &:nth-child(2) {
      background-image: url("./img/kenny-eliason-LoV0EpSG2-M-unsplash.jpg");
    }

    &:nth-child(3) {
      background-image: url("./img/marvin-meyer-2SDjvx5jEZQ-unsplash.jpg");
    }

    &:nth-child(4) {
      background-image: url("./img/andrew-knechel-gG6yehL64fo-unsplash.jpg");
    }

    &:nth-child(5) {
      background-image: url("./img/zane-lee-BbXYnYigR0U-unsplash.jpg");
    }

    &:nth-child(6) {
      background-image: url("./img/armando-castillejos-a1GYyF9mt_8-unsplash.jpg");
      min-height: calc(100vh - (var(--v-gutter) * 2));
    }
  }

  &__header,
  &__body {
    background: white;
    border: 0.8rem solid var(--primary);
    max-width: 80rem;
    padding: 1.6rem var(--m);
  }

  &__header {
    margin: var(--m) auto;
  }

  &__body {
    margin: 2.4rem auto;
    text-align: left;

    &.tl-dr {
      font-size: var(--reg);

      h2 {
        font-size: var(--m);
      }
    }

    h1, h2, h3, h4 {
      text-align: left;
    }
  }
}
